<template>
    <div>
        <el-table :data="videos">
            <el-table-column prop="video_name" label="视频名称"></el-table-column>
            <el-table-column prop="video_intro" label="视频简介" width="700"></el-table-column>
            <el-table-column label="视频预览">
                <template slot-scope="scope">
                    <a :href="scope.row.video_url">{{scope.row.key}}</a>
                </template>
            </el-table-column>
            <el-table-column label="审核状态">
                <template slot-scope="scope">
                    <span>{{labelMap[scope.row.status]}}</span>
                </template>
            </el-table-column>
           <el-table-column label="操作">
               <template slot-scope="scope">
                   <el-button @click="edit(scope.row)" type="primary" size="medium">编辑</el-button>
               </template>
           </el-table-column>
        </el-table>
        <el-dialog :visible.sync="show">
            <el-form>
                <el-form-item label="视频名称">
                    <el-input v-model="form.video_name"></el-input>
                </el-form-item>
                <el-form-item label="视频简介">
                    <el-input v-model="form.video_intro" type="textarea" :rows="6"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submit">确认修改</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { getMyVideos,updateVideo } from '../../api/index'
import { getUserId } from '../../utils/store'
export default {
    data(){
        return{
            videos:[],
            labelMap:{
                pending:'审核中',
                approve:'已通过',
                refuse:'已拒绝'
            },
            id:'',
            form:{
                video_name:'',
                video_intro:'',
                video_url:'',
                key:''
            },
            show:false
        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getMyVideos(getUserId()).then(res=>{
                if(res.data.code===0){
                    this.videos = res.data.data
                }
            })
        },
        edit(row){
            this.form = JSON.parse(JSON.stringify(row))
            this.id = row._id.$id
            this.show = true
        },
        submit(){
            if(!this.form.video_name){
                this.$message.warning('请输入视频名称')
                return
            }
            if(!this.form.video_intro){
                this.$message.warning('请输入视频简介')
                return
            }
            if(!this.form.key){
                this.$message.warning('请上传视频')
                return
            }
            updateVideo(this.id,getUserId(),this.form.video_name,this.form.video_intro,this.form.key).then(res=>{
                if(res.data.code===0){
                    this.show = false
                    this.$message.success('更新成功')
                    this.fetchData()
                }
            })
        },
    }
}
</script>

<style>

</style>